<template>
  <div>
    <p>Jos ne radi - Popusti</p>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>